/*
 * @Description: 购物车
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-16 17:39:04
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-23 09:55:41
 */
import {
    Cart,
    Order,
    Personal,
    Product,
} from '@/api/index'
import { Com }  from '@/api/index';

export async function ShoppingCart(data) {
    let res = await Cart.ShoppingCart(data)
    return res;
}

//删除购物车
export async function delProduct(data) {
    let res = await Cart.delProduct(data)
    return res;
}

export async function addCollect(data) {
    let res = await Cart.addCollect(data)
}
//确认订单
//我的收获地址
export async function myAddress(data) {
    let res = await Order.myAddress(data)
    return res;
}


//确认下单
// 删除地址
export async function delAdd(data) {
    let res = await Personal.delAdd(data)
    return res;
}

//创建订单
export async function createOrder(data) {
    let res = await Product.createOrder(data)
    return res;
}
//支付宝支付
export async function tradePreCreatePay(data){
    let res = await Cart.tradePreCreatePay(data)
    return res;
}

//支付
export async function aliPay(data) {
    let res = await Product.aliPay(data)
    return res;
}
//获取订单状态
export async function getOrderState(data){
    let res = await Order.getOrderState(data)
    return res;
  }

// 是否支持货到付款
export async function isCash(data){
    let res = await Product.isCash(data)
    return res;
}

//订单详情
export async function orderDetail(data){
    let res = await Com.orderDetail(data)
    return res;
}

export async function buyNow(data){
    let res = await Cart.buyNow(data)
    return res; 
}