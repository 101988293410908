<!--
 * @Description: 购物车支付
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 16:18:11
 * @LastEditors: zmj
 * @LastEditTime: 2022-03-21 13:16:25
-->
<template>
  <div>
    <div>
      <div class="w ww zhifu">
        <div class="bread">{{this.$t('position')}} > <a @click="goPage">{{this.$t('homePage')}}</a> > <a>{{this.$t('methods.pay1')}}</a></div>
        <div class="zhifu_top">
          <div>
            <div class="pc_fl">
              <div class="fl zhifu_top_1">
                <img src="@/assets/img/58.png" width="100%" />
              </div>
              <div class="fl zhifu_top_2">
                <h3>{{this.$t('submitSuccess')}}</h3>
                <p>
                  {{this.$t('orderTips')}}
                </p>
              </div>
              <div class="clear"></div>
            </div>
            <div class="pc_fr zhifu_top_3">
             {{ this.$t('AmountPayable')}}：<b>￥{{ info.orderTotal }}</b>
            </div>
            <div class="clear"></div>
          </div>
          <div class="zhifu_top_xq">
            <div class="nav_but">
              <button class="btn01" @click="openShutManager">
                {{this.$t('orderDetail')}}<img src="@/assets/img/59.png" />
              </button>
            </div>
            <p>{{this.$t('orderNum')}}：{{ info.orderCode }}</p>
            <div id="box1" v-show="showShut">
              <p>{{this.$t('name')}}：{{ info.recName }}</p>
              <p>{{this.$t('Harvest')}}：{{ info.recAddress }}</p>
              <p>{{this.$t('orderNum')}}：{{ info.orderCode }}</p>
              <p>{{this.$t('price')}}：￥{{ info.orderTotal }}</p>
            </div>
          </div>
        </div>

        <div class="zhifu_cn">
          <h2>{{this.$t('payPlatform')}}</h2>
          <ul class="zx_t">
            <li
              :class="{ active: activeWayIndex == i }"
              @mouseover="selectWay(i)"
              v-for="(item, i) in payWay"
              :key="i"
            >
              <img :src="item.img" />{{ item.text }}
            </li>
          </ul>
          <ul class="">
            <li class="thisclass" v-show="activeWayIndex == 0">
              <div class="zhifu_cn_wx">
                <div class="fl zhifu_cn_wx_lf">
                  <iframe :srcdoc="AlipayImg" style="width:100%;height:280px;border:none;"></iframe>
                  <h3>{{this.$t('Alipay')}}<br />{{this.$t('scan')}}</h3>
                </div>
                <div class="clear"></div>
              </div>
            </li>
            <li v-show="activeWayIndex == 1">
              <div class="zhifu_cn_wx">
                <div class="fl zhifu_cn_wx_lf">
                  <div><img src="@/assets/img/64.png" width="100%" /></div>
                  <h3>{{this.$t('weChatscan')}}<br />{{this.$t('scan')}}</h3>
                </div>
                <div class="pc_fl zhifu_cn_wx_rt">
                  <img src="@/assets/img/65.png" />
                </div>
                <div class="clear"></div>
              </div>
            </li>
            <li v-show="activeWayIndex == 2">内容3</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { aliPay,tradePreCreatePay,getOrderState } from "./model";
import { IMGURL } from "@/utils/constant";
import { showLayer } from "@/utils/com";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  components: {
  },
  computed: {
    ...mapState("product", ["payResult"]),
  },
  data() {
    return {
      IMGURL,
      activeWayIndex: 0,
      AlipayImg:'',
      payWay: [
        { text: "支付宝支付", img: require("@/assets/img/61.png") },
        // { text: "微信支付", img: require("@/assets/img/62.png") },
        // { text: "银联卡支付", img: require("@/assets/img/63.png") },
      ],
      showShut: false,
      payCodeUrl: "",
      orderInfo:{
        subject:'',
        totalAmount:'',
        outTradeNo:'',
       
      },
      owid:{
        owid:''
      }, //订单主键
      timer:'',
      info:''
    };
  },
  created() {
   if(this.$route.query.info){
     this.info =this.$route.query.info && JSON.parse(this.$route.query.info)
    this.owid.owid =this.info.owid;
    this.orderInfo.subject = this.info.orderCode;
    this.orderInfo.totalAmount = this.info.orderRealAmount;
    this.orderInfo.outTradeNo = this.info.orderCode;
   }else {
     this.info = this.$store.state.product.payResult;
    this.owid.owid =this.info.owid;
    this.orderInfo.subject = this.info.orderCode;
    this.orderInfo.totalAmount = this.info.orderRealAmount;
    this.orderInfo.outTradeNo = this.info.orderCode;
   }
      // this.owid.owid = this.$store.state.product.payResult.owid;
      // this.orderInfo.subject = this.$store.state.product.payResult.orderCode;
      // this.orderInfo.totalAmount = this.$store.state.product.payResult.orderTotal;
      // this.orderInfo.outTradeNo = this.$store.state.product.payResult.orderCode;
      // console.log(this.$store.state.product.payResult);
      aliPay({
        subject:this.orderInfo.subject,
        totalAmount:this.orderInfo.totalAmount,
        outTradeNo:this.orderInfo.outTradeNo,
        qrPayMode:4,
        qrcodeWidth:270

      }).then(res =>{
        console.log(res);
        this.AlipayImg = res;
      })
     
  },
  mounted(){
    this.getOrderState();
    this.timer = window.setInterval(()=>{
      setTimeout(()=>{
        this.getOrderState();
      },0)
    },5000)
  },
  methods: {
    selectWay(index) {
      this.activeWayIndex = index;
    },
    openShutManager() {
      this.showShut = !this.showShut;
    },

    getOrderState(){
        getOrderState(this.owid).then(res=>{
          if(res.state === 1){
            this.$router.push({path:'/paySuccess',query:{owid:this.owid.owid}})
           
          }else{
             showLayer("toast", this.$t('scanPay'));
          }
        })
    },
    goPage(){
      this.$router.push('/')
    }
  },
  destroyed(){
     clearInterval(this.timer)
  }
};
</script>

<style></style>
